import React, { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { motion } from "framer-motion";

import admin from "../assets/image/admin .svg";
import checkpoint from "../assets/image/checkpoint.svg";
import entrypoint from "../assets/image/entry.svg";
import khumbuIcon from "../assets/image/khumbu.png";
import namche from "../assets/image/hero-image.jpg";

// CONTEXT
import StateContext from "../context/StateContext";

const cookies = new Cookies();

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useContext(StateContext);
  const token = appState.credentials.token.trekcard_token;

  const loginList = [
    { name: "Entry Point", link: "/entrypoint", image: entrypoint },
    { name: "Check Point", link: "/checkpoint", image: checkpoint },
    { name: "Admin Dashboard", link: "/admin", image: admin },
  ];

  useEffect(() => {
    if (!token && location.pathname !== "/cpanel") {
      navigate("/");
    } else if (location.pathname === "/cpanel") {
      navigate("/cpanel");
    } else {
      if (appState.credentials.token.trekcard_location === "checkpoint") {
        navigate(
          "/checkpoint/dashboard/" + cookies.get("trekcard_location_id")
        );
      }
      if (appState.credentials.token.trekcard_location === "admin") {
        navigate("/admin/dashboard");
      }
      if (appState.credentials.token.trekcard_location === "entrypoint") {
        navigate("/entrypoint/dashboard");
      }
    }
  }, [token]);

  return (
    <main
      className="checkpost-home"
      style={{
        backgroundImage: `linear-gradient(0deg, #00000088 30%, #ffffff44 100%),url(${namche})`,
      }}
    >
      <div className="content">
        <div className="container">
          <div className="login-body">
            <div className="text-content">
              <motion.h1
                className="new"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: 0.5 }}
              >
                TREK CARD
              </motion.h1>
              <motion.p
                className="medium-text"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: 0.7 }}
              >
                Trekking Recreation Expedition in Khumbu
              </motion.p>
              <motion.div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  transition: "all .3s ease-in-out",
                  margin: "20px 0",
                  minWidth: "330px",
                }}
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: Math.random() }}
              >
                <Link
                  to={"/registration"}
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <div className="logo-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ef4260"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-user"
                    >
                      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
                      <circle cx="12" cy="7" r="4" />
                    </svg>
                  </div>
                  <div className="text-container">
                    <p
                      style={{
                        color: "#2e3192",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Visitor Registration
                    </p>
                  </div>
                </Link>
              </motion.div>
              <motion.div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  transition: "all .3s ease-in-out",
                  minWidth: "330px",
                }}
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: Math.random() }}
              >
                <Link
                  to={"/guideregistration"}
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <div className="logo-container">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#ef4260"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-user-cog"
                    >
                      <circle cx="18" cy="15" r="3" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M10 15H6a4 4 0 0 0-4 4v2" />
                      <path d="m21.7 16.4-.9-.3" />
                      <path d="m15.2 13.9-.9-.3" />
                      <path d="m16.6 18.7.3-.9" />
                      <path d="m19.1 12.2.3-.9" />
                      <path d="m19.6 18.7-.4-1" />
                      <path d="m16.8 12.3-.4-1" />
                      <path d="m14.3 16.6 1-.4" />
                      <path d="m20.7 13.8 1-.4" />
                    </svg>
                  </div>
                  <div className="text-container">
                    <p
                      style={{
                        color: "#2e3192",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Guide Registration
                    </p>
                  </div>
                </Link>
              </motion.div>
            </div>
            <div className="form-content new">
              <motion.div
                className="text-content"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
                transition={{ duration: 0.5 }}
              >
                <div className="logo">
                  <img src={khumbuIcon} alt="" />
                </div>
                <div className="text-container">
                  <p className="medium-bold-text">
                    Khumbu Pasanglhamu Rural Municipality
                  </p>
                </div>
              </motion.div>
              <div className="menu-container new">
                {loginList.map((item, i) => (
                  <motion.div
                    className="login-content"
                    key={i}
                    initial={{ y: 100 }}
                    animate={{ y: 0 }}
                    exit={{ y: 100 }}
                    transition={
                      (i === 0 && { duration: 0.7 }) ||
                      (i === 1 && { duration: 0.9 }) ||
                      (i === 2 && { duration: 1.1 })
                    }
                  >
                    <Link to={item.link}>
                      <div className="logo-container">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="text-container">
                        <p>{item.name}</p>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-text">
          <p>Powered by: Affix Link Pvt. Ltd.</p>
        </div>
      </div>
    </main>
  );
};

export default LandingPage;
